<template>
  <div class="select-menu select-price-menu">
    <div v-if="options.length > 0" :key="null" class="clear" @click="onClear">
      <span>{{ t.clear }}</span>
    </div>
    <div class="buttons">
      <button type="button" :class="{ active: selectedItems.priceType === 'retail' }" @click="selectPriceType('retail')">{{ t.fullPrice }}</button>
      <button type="button" :class="{ active: selectedItems.priceType === 'emi' }" @click="selectPriceType('emi')">{{ t.financing }}</button>
    </div>
    <SuggestedSearchItem class="select-item" v-for="item in options" :key="item.label" :label="item.label" :count="item.count" @click="onClick($event, item)" />
  </div>
</template>

<script>
import SuggestedSearchItem from '@/components/Home/Hero/SuggestedSearchItem.vue'
import { langMixin } from '@/lang'

export default {
  name: 'PriceMenu',
  components: { SuggestedSearchItem },
  mixins: [langMixin('Home')],
  inject: ['selectedItems', 'setSelectedItem'],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['select', 'clear'],
  methods: {
    onClick(event, option) {
      event.preventDefault()
      this.$emit('select', option)
    },
    selectPriceType(priceType) {
      this.setSelectedItem({ priceType })
    },
    onClear(event) {
      event.preventDefault()
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins';

.select-menu {
  @include select-menu();

  .suggested-search-item {
    grid-template-columns: minmax(0, 1fr);
  }

  .buttons {
    background-color: var(--neutral-30);
    padding: 1rem 0.5rem;
    display: flex;
    gap: 0.5rem;

    > button {
      flex: 1;
      border: none;
      border-radius: 1.5rem;
      padding: 1rem 0.75rem;
      cursor: pointer;
      background-color: transparent;

      &.active {
        background-color: var(--background);
      }
    }
  }
}
</style>

<template>
  <section class="benefits vertical-padding">
    <h2 class="title">{{ t.benefitTitle }}</h2>
    <p class="subtitle">{{ t.benefitSubtitle }}</p>
    <div class="card-list">
      <div v-for="card in benefitCards" :key="card.title" class="card-item">
        <img :src="card.imageUrl" loading="lazy" />
        <h4>{{ card.title }}</h4>
        <p>{{ card.subtitle }}</p>
      </div>
    </div>
  </section>
</template>
<script>
import { langMixin } from '@/lang'

export default {
  name: 'BenefitsComponent',
  mixins: [langMixin('Home')],
  computed: {
    benefitCards() {
      return JSON.parse(this.t.benefitCards || '[]')
    }
  }
}
</script>
<style lang="scss">
section.benefits {
  padding: {
    left: var(--content-side-padding);
    right: var(--content-side-padding);
  }
  background: var(--dark-background);

  @media screen and (min-width: 75rem) {
    background: var(--dark-background) url('/assets/squiggly3.svg') no-repeat 100% 12%;
  }

  color: var(--background);

  > h2 {
    margin-bottom: 1rem;
    color: var(--background);
  }

  > p {
    margin-bottom: 4rem;
  }

  .card-list {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    color: var(--font-color);
  }

  .card-item {
    background-color: var(--background);
    border-radius: 1rem;
    padding: 1.5rem;
    flex: 1;

    h4 {
      margin-top: 1.25rem;
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 1.5rem;
      font-family: var(--heading-font);
      color: var(--accent);
    }

    p {
      font-size: 1.25rem;
    }
  }

  @media screen and (min-width: 48rem) {
    .card-list {
      flex-direction: row;
    }
  }
}
</style>

import { createRouter, createWebHistory } from 'vue-router'
// import { nextTick } from 'vue'
import HomeView from '@/views/Home/HomeView.vue'
import Profile from '@/views/Profile/Profile.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView
    },
    {
      path: '/contact',
      name: 'contact',
      component: () => import('../views/ContactView.vue')
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('@/views/TermsAndConditionView.vue')
    },
    {
      path: '/privacy',
      name: 'privacy',
      component: () => import('@/views/PrivacyPolicyView.vue')
    },
    {
      path: '/cookie-policies',
      name: 'cookiePolicies',
      component: () => import('@/views/CookiePoliciesView.vue')
    },
    {
      path: '/search/:brand?/:model?',
      name: 'search',
      component: () => import('../views/SearchView.vue')
    },
    {
      path: '/listing/:id',
      name: 'listing',
      component: () => import('../views/Listing/ListingView.vue')
    },
    {
      path: '/me',
      name: 'me',
      component: Profile,
      children: [
        {
          path: 'account',
          name: 'account',
          component: () => import('@/views/Profile/Account.vue'),
          props: {}
        },
        {
          path: 'favorites',
          name: 'favorites',
          component: () => import('@/views/Profile/Favorites.vue')
        },
        {
          path: 'saved-searches',
          name: 'savedSearches',
          component: () => import('@/views/Profile/SavedSearches.vue')
        },
        {
          path: 'my-orders',
          name: 'My Orders',
          component: () => import('@/views/Profile/MyOrders.vue')
        },
        {
          path: 'my-leads',
          name: 'My Leads',
          component: () => import('@/views/Profile/MyLead.vue')
        },
        {
          path: 'test-drives',
          name: 'My Test Drives',
          component: () => import('@/views/Profile/MyTestDrive.vue')
        }
      ]
    },
    {
      path: '/start/:id',
      name: 'intro',
      component: () => import('../views/Intro/JourneyIntro.vue')
    },
    {
      path: '/order/:id',
      name: 'order',
      component: () => import('../views/Order/Order.vue'),
      alias: ['/ordrer/:id']
    }
  ]
})

// router.afterEach(to => {
//   nextTick(() => {
//     document.title = to.meta.title || 'AAM'
//   })
// })

export default router

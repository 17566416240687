import './assets/styles/base.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const app = createApp(App)

app.use(router)

app.mount('#app')

window.generateNationalities = () => {
  const getCountries = () => {
    const lang = document.documentElement.lang || 'en'
    const A = 65
    const Z = 90
    const countryName = new Intl.DisplayNames([lang], { type: 'region' })
    const countries = {}

    for (let i = A; i <= Z; ++i) {
      for (let j = A; j <= Z; ++j) {
        let code = String.fromCharCode(i) + String.fromCharCode(j)
        let name = countryName.of(code)
        if (code !== name) {
          countries[code] = {
            name: name,
            code: code
          }
        }
      }
    }
    return countries
  }

  const countryDropdownOptions = Object.values(getCountries()).map(item => ({ id: item.code, value: item.name }))

  countryDropdownOptions.sort((a, b) => a.value.localeCompare(b.value))

  const seenNames = new Set()
  const seenIds = new Set()
  const uniqueCountries = []
  // Use to debug the duplicated if needed
  const duplicates = []

  countryDropdownOptions.forEach(item => {
    if (!seenNames.has(item.value) && !seenIds.has(item.id)) {
      seenNames.add(item.value)
      seenIds.add(item.id)
      uniqueCountries.push(item)
    } else {
      duplicates.push(item)
    }
  })

  return uniqueCountries.length ? uniqueCountries : [{ id: '', value: '' }]
}

const regionOnAAM = ['Al Shamal', 'Al Khor', 'Umm Salal', 'Al Daayen', 'Al Rayyan', 'Ad Dawhah (Doha)', 'Al Wakrah', 'Al Shahaniya', 'Other']

window.getRegionOnAAM = function () {
  return regionOnAAM
}

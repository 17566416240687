export const querierMixin = {
  data() {
    return {
      abortController: new AbortController()
    }
  },
  beforeDestroy() {
    this.abortController?.abort()
  },
  methods: {
    queryApi(query, variables) {
      return new Promise((resolve, reject) => {
        window.seezSdk
          .queryApi(query, variables, this.abortController?.signal)
          .then(resolve)
          .catch(e => {
            if (e.name !== 'AbortError') reject(e)
          })
      })
    }
  }
}

<template>
  <div :class="{ navMobileMenu: true, showed: statuses.showHomeMenu, hideWithoutTransition: !showMenu }">
    <ul>
      <li>
        <RouterLink active-class="active" to="/search">{{ t.search_cars }}</RouterLink>
      </li>
      <li>
        <a href="https://www.aamotors.com/our_businesses/cpov" target="_blank">{{ t.about }}</a>
      </li>
      <li>
        <a href="/#frequently-asked-questions">{{ t.faq }}</a>
      </li>
      <li>
        <RouterLink active-class="active" to="/contact">{{ t.contact }}</RouterLink>
      </li>
      <li v-if="!currentUser" key="login" @click="$root.login">
        <span>{{ t.login }}</span>
        <span><IconChevronRight /></span>
      </li>
      <li v-if="currentUser" key="profile" @click="handleShowProfileMenu">
        <span>{{ t.profile }}</span>
        <span><IconChevronRight /></span>
      </li>
      <li>
        <LanguageDropdown class="mobile-lang-btn" />
      </li>
    </ul>
  </div>
  <div :class="{ profileMobileMenu: true, showed: statuses.showProfileMenu, hideWithoutTransition: !showMenu }">
    <ul>
      <li key="back" @click="handleShowHomeMenu">
        <span><IconChevronLeft /></span>
        <span>{{ t.profile }}</span>
      </li>
      <li>
        <RouterLink to="/me/account">{{ languageResources?.Profile?.my_account }}</RouterLink>
      </li>
      <li>
        <RouterLink to="/me/favorites">{{ languageResources?.Profile?.favorites }}</RouterLink>
      </li>
      <li>
        <RouterLink to="/me/saved-searches">{{ languageResources?.Profile?.saved_searches }}</RouterLink>
      </li>
      <li class="flag">
        <RouterLink to="/me/my-orders">{{ languageResources?.Profile?.my_orders }}</RouterLink>
      </li>
      <li class="flag">
        <RouterLink to="/me/my-leads">{{ languageResources?.Profile?.my_leads }}</RouterLink>
      </li>
      <li class="flag">
        <RouterLink to="/me/test-drives">{{ languageResources?.Profile?.test_drives }}</RouterLink>
      </li>
      <li key="logout" @click="logout">
        <span><IconLogout /></span>
        <span>{{ languageResources?.Profile?.logout }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import IconLogout from '@/assets/icons/logout.svg'
import IconChevronRight from '@/assets/icons/chevron-right.svg'
import IconChevronLeft from '@/assets/icons/chevron-left.svg'
import { RouterLink } from 'vue-router'
import { langMixin } from '@/lang'
import LanguageDropdown from './LanguageDropdown.vue'

export default {
  name: 'NavMobileMenu',
  components: {
    IconLogout,
    IconChevronRight,
    IconChevronLeft,
    RouterLink,
    LanguageDropdown
  },
  mixins: [langMixin('Common')],
  inject: ['currentUser'],
  props: {
    statuses: {
      type: Boolean,
      default: () => {}
    }
  },
  emits: ['change'],
  data() {
    return {
      oldBodyOverflow: document.body.style.overflow
    }
  },
  computed: {
    showMenu() {
      return this.statuses.showHomeMenu || this.statuses.showProfileMenu
    },
    showLeadTestDriveOrder() {
      const style = {
        NONE: 'none',
        CONTENT: 'content'
      }

      const isProdEnv = import.meta.env.VITE_ENV_LABEL === 'prod'
      return isProdEnv ? style.NONE : style.CONTENT
    }
  },
  watch: {
    $route() {
      if (this.showMenu) {
        document.body.style.overflow = this.oldBodyOverflow

        this.$emit('change', {
          showProfileMenu: false,
          showHomeMenu: false
        })
      }
    },
    showMenu(value) {
      if (value) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = this.oldBodyOverflow
      }
    }
  },
  methods: {
    logout() {
      window.seezSdk.showLogout()
    },
    handleShowProfileMenu() {
      this.$emit('change', {
        showProfileMenu: true,
        showHomeMenu: false
      })
    },
    handleShowHomeMenu() {
      this.$emit('change', {
        showProfileMenu: false,
        showHomeMenu: true
      })
    }
  }
}
</script>

<style lang="scss">
.navMobileMenu,
.profileMobileMenu {
  overflow-y: auto;
  transition: transform 0.4s;

  &.hideWithoutTransition {
    transition: unset;
  }

  svg {
    &:lang(ar) {
      transform: scaleX(-1);
    }
  }

  margin: 0;
  background: var(--accent);
  position: fixed;
  top: var(--header-height);
  left: 0;
  right: 0;
  bottom: 0;

  > ul {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.25rem 2.5rem;

    > li {
      padding: 0.625rem 1.25rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
      font-size: 1.125rem;

      a,
      span {
        cursor: pointer;
        color: var(--background);
      }

      span {
        display: flex;
        align-items: center;
      }

      a {
        padding: 0;
      }
    }
  }

  @media screen and (min-width: 75rem) {
    display: none;
  }
}

.navMobileMenu {
  transform: translateX(-100%);

  &.showed {
    transform: translateX(0);
  }
}

.profileMobileMenu {
  transform: translateX(100%);

  &.showed {
    transform: translateX(0);
  }

  > ul {
    > li {
      padding-left: 1.25rem;

      &:first-child {
        font-weight: 700;
      }

      &:first-child,
      &:last-child {
        padding-left: 0;
      }
    }
  }
}

.mobile-lang-btn {
  span {
    font-size: 1.125rem;
  }

  .language-dropdown-list {
    inset-block-start: 3rem;
    inset-inline-start: 0;
    inset-inline-end: unset;
  }
}
.flag {
  display: v-bind(showLeadTestDriveOrder) !important;
}
</style>

<template>
  <section class="finacing vertical-padding">
    <div class="media-box">
      <h2 class="title">{{ t.financingYourDream }}</h2>
      <img v-if="isMobile" src="/src/assets/buy-cars-mobile.webp" loading="lazy" />
      <img v-else src="/src/assets/buy-cars.webp" loading="lazy" />
    </div>
    <div class="text-box">
      <h2 class="title">{{ t.financingYourDream }}</h2>
      <p class="subtitle">{{ t.financingYourDreamDesc }}</p>
      <ul v-if="financingItems.length">
        <li className="subtitle" v-for="text in financingItems" :key="text">{{ text }}</li>
      </ul>
      <!-- <a href="#"> {{ t.readMore }} <IconCircleArrowRight :class="{ flipped: textDirection === 'rtl' }" /> </a> -->
    </div>
  </section>
</template>
<script>
import { langMixin } from '@/lang.js'

export default {
  name: 'BenefitsComponent',
  mixins: [langMixin('Home')],
  data() {
    return {
      isMobile: window.innerWidth < 768
    }
  },
  computed: {
    financingItems() {
      return JSON.parse(this.t.financingItems || '[]')
    }
  }
}
</script>
<style lang="scss">
section.finacing {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  padding: {
    left: var(--content-side-padding);
    right: var(--content-side-padding);
  }

  @media screen and (min-width: 75rem) {
    background: url('/assets/squiggly3.svg') no-repeat 100% 61%;
  }

  h2 {
    margin-bottom: 2rem;
  }

  .text-box {
    h2 {
      display: none;
    }

    p {
      margin-bottom: 1.5rem;
      color: var(--neutral-70);
    }

    a {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    li {
      color: var(--neutral-70);
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding-inline-start: 1rem;
    }
  }

  .media-box {
    img {
      max-height: 37rem;
      width: 100%;
      object-fit: cover;
      border-radius: 1rem;
    }
  }

  @media screen and (min-width: 75rem) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .text-box {
      max-width: 715px;
      h2 {
        display: block;
      }
    }

    .media-box {
      order: 2;
      h2 {
        display: none;
      }

      img {
        height: max(40vw, 35rem);
      }
    }
  }
}
</style>

<template>
  <div class="card">
    <p class="review">{{ item.review }}</p>
    <p class="reviewShort" v-if="!expanded">{{ item.reviewShort }}</p>
    <p class="reviewExpand" v-else>{{ item.review }}</p>
    <button class="toggleSee" @click="expanded = !expanded">{{ expanded ? t.seeLess : t.seeMore }}</button>
    <div className="author">{{ item.username }}</div>
    <span class="customer">{{ item.type }}</span>
  </div>
</template>

<script>
import { langMixin } from '@/lang.js'
export default {
  name: 'CarTypeCard',
  mixins: [langMixin('Home')],
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      expanded: false
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  padding: 3.5rem 3rem;
  border-radius: 1rem;
  background-color: var(--background);

  .review {
    font-size: 1.625rem;
    margin-bottom: 2.5rem;
    color: var(--font-color);
    font-family: var(--heading-font);
  }

  .author {
    font-size: 1.5rem;
    margin-bottom: 0.25rem;
    color: #5a0173;
    font-family: var(--heading-font);
  }

  .customer {
    color: #626262;
  }

  .reviewShort,
  .reviewExpand {
    font-size: 1.375rem;
    line-height: 2.2rem;
    color: var(--font-color);
    font-family: var(--heading-font);
    display: none;
  }
  .toggleSee {
    margin-bottom: 2.5rem;
    background-color: transparent;
    border: none;
    padding: 0;
    border-bottom: 1px solid var(--accent);
    color: var(--accent);
    font-size: 1rem;
    font-weight: 700;
    cursor: pointer;
    display: none;
  }
  @media screen and (max-width: 36rem) {
    padding: 1.875rem 1rem;
    .review {
      display: none;
    }
    .reviewShort,
    .reviewExpand,
    .toggleSee {
      display: block;
    }
  }
}
</style>

<template>
  <div class="select-menu">
    <div v-if="options.length > 0" :key="null" class="clear" @click="onClear">
      <span>{{ t.clear }}</span>
    </div>
    <SuggestedSearchItem class="select-item" v-for="item in options" :key="item.label" :label="item.label" :count="item.count" @click="onClick($event, item)" />
  </div>
</template>

<script>
import SuggestedSearchItem from '@/components/Home/Hero/SuggestedSearchItem.vue'
import { langMixin } from '@/lang'

export default {
  name: 'YearMenu',
  components: { SuggestedSearchItem },
  mixins: [langMixin('Home')],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    }
  },
  emits: ['select', 'clear'],
  methods: {
    onClick(event, option) {
      event.preventDefault()
      this.$emit('select', option)
    },
    onClear(event) {
      event.preventDefault()
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins';

.select-menu {
  @include select-menu();

  .suggested-search-item {
    grid-template-columns: minmax(0, 1fr);
  }
}
</style>

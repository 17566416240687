<template>
  <div class="carousel">
    <div class="carousel-inner" ref="carouselContainer" :style="desktop ? { transform: `translateX(${isRTL ? -translateX : translateX}px)` } : {}" @scroll="handleScroll">
      <slot />
    </div>
    <div class="carousel-controls">
      <div :class="{ 'carousel-btn': true, prev: true, flipped: isRTL }" @click="previous" :data-disabled="disabledPrev">
        <slot name="prev">
          <button>
            <ChevronLeft />
          </button>
        </slot>
      </div>
      <div :class="{ 'carousel-btn': true, next: true, flipped: isRTL }" @click="next" :data-disabled="disabledNext">
        <slot name="next">
          <button>
            <ChevronRight />
          </button>
        </slot>
      </div>
    </div>
    <div class="carousel-pagination">
      <ProgressBar :percent="percent" />
    </div>
  </div>
</template>

<script>
import ChevronRight from '@/assets/icons/chevron-right.svg'
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import { langMixin } from '@/lang.js'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'CarouselComponent',
  components: {
    ChevronRight,
    ChevronLeft,
    ProgressBar
  },
  mixins: [langMixin()],
  props: {
    pageCount: {
      type: Number,
      default: 0
    },
    itemsPerPage: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      currentIndex: 0,
      containerWidth: 0,
      itemWidth: 0,
      percent: 0,
      intervalId: null,
      translateX: 0,
      desktop: false
    }
  },
  computed: {
    disabledNext() {
      return this.currentIndex >= this.pageCount - 1
    },
    disabledPrev() {
      return this.currentIndex <= 0
    },
    isRTL() {
      return this.textDirection === 'rtl'
    }
  },
  watch: {
    pageCount() {
      this.updatePercent()
    }
  },
  mounted() {
    this.checkDesktop()
    this.containerWidth = this.$el.offsetWidth
    this.calculateItemWidth()
    window.addEventListener('resize', this.calculateItemWidth)
    window.addEventListener('resize', this.checkDesktop)
    this.startAutoScroll()
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.calculateItemWidth)
    window.removeEventListener('resize', this.checkDesktop)
    clearInterval(this.intervalId)
  },
  methods: {
    handleScroll() {
      if (!this.desktop) {
        this.updatePercent()
      }
    },
    calculateItemWidth() {
      this.itemWidth = this.containerWidth / this.itemsPerPage
      this.translateX = -this.currentIndex * this.itemWidth
    },
    updatePercent() {
      this.percent = (1 / this.pageCount + Math.abs(this.$refs?.carouselContainer?.scrollLeft) / this.$refs?.carouselContainer?.scrollWidth) * 100
    },
    next() {
      if (this.currentIndex < this.pageCount - 1) {
        this.currentIndex++
      } else {
        this.currentIndex = 0
      }
      this.scrollToCurrentIndex()
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--
      } else {
        this.currentIndex = this.pageCount - 1
      }
      this.scrollToCurrentIndex()
    },
    scrollToCurrentIndex() {
      if (this.desktop) {
        this.translateX = -this.currentIndex * this.itemWidth
      } else {
        const scrollAmount = this.currentIndex * this.itemWidth
        this.$refs.carouselContainer.scrollTo({
          left: this.isRTL ? -scrollAmount : scrollAmount,
          behavior: 'smooth'
        })
      }
    },
    startAutoScroll() {
      this.intervalId = setInterval(this.next, 5000)
    },
    checkDesktop() {
      //We can change this as needed
      this.desktop = window.innerWidth > 1200
    }
  }
}
</script>

<style lang="scss">
.carousel {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner {
  display: flex;
  transition: transform 1s ease;

  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
  }

  @media screen and (max-width: 75rem) {
    overflow: auto;
  }
}

.carousel-controls {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.carousel-btn {
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--background);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  &[data-disabled='true'] {
    opacity: 0.5;
    cursor: not-allowed;

    > button {
      cursor: not-allowed;
    }
  }
}

.carousel-pagination {
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
}
</style>

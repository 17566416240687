<template>
  <div :class="{ card: true, highlight: item.isHighlight }">
    <div className="car-box">
      <img :src="item.imageUrl" alt="car" className="car-img" loading="lazy" />
      <div className="feature-img" v-if="item.featureIcon">
        <img :src="item.featureIcon" alt="feature" loading="lazy" />
      </div>
    </div>
    <h4>
      {{ item.displayName || item.name }} <span v-if="item.count">({{ item.count }})</span>
    </h4>
    <!-- <p>{{ item.description }}</p> -->
  </div>
</template>

<script>
export default {
  name: 'CarTypeCard',
  props: {
    item: {
      type: Object,
      default() {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  padding: 2.625rem 1.75rem 2.25rem 1.75rem;
  max-width: 17.5rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  box-shadow: 1px 2px 10px 0px rgba(0, 0, 0, 0.07);
  cursor: pointer;
  background-color: var(--background);

  &.highlight {
    background-color: #c8f9f2;
  }

  &:hover {
    .car-box {
      transform: scale(1.05);
    }
  }

  .car-box {
    position: relative;
    transition: transform 0.3s;
    display: flex;
    justify-content: center;

    html[dir='rtl'] & {
      img,
      .feature-img {
        transform: scaleX(-1);
      }

      .feature-img {
        left: unset;
        right: 0.5rem;
      }
    }
  }

  .feature-img {
    position: absolute;
    left: 0.5rem;
    top: -0.5rem;
  }

  .car-img {
    height: 5.5rem;
    object-fit: contain;
    margin-bottom: 2.5rem;
    width: 14rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    font-family: var(--heading-font);
    color: var(--black);
  }

  p {
    color: #626262;
    margin: 0;
  }
}
</style>

<template>
  <DropdownComponent @click="onClick" data-test-id="search_by_model" @close="onClose">
    <div class="select-field" :data-disabled="!selectedItems.brand">
      <input class="input-field" :value="searchValue" @input="handleSearchChange" @blur="onBlur" :placeholder="placeholder" :disabled="!selectedItems.brand" />
      <IconChevronDown />
    </div>
    <template #content="slotProps" v-if="models?.length > 0">
      <BrandMenu @select-brand="selectBrand($event, slotProps.setOpen)" :type="'model'" :options="models" @clear="clear(slotProps.setOpen)" />
    </template>
  </DropdownComponent>
</template>

<script>
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import DropdownComponent from '@/components/Dropdown.vue'
import BrandMenu from '@/components/Home/Hero/BrandMenu.vue'

export default {
  name: 'SelectModel',
  components: { DropdownComponent, IconChevronDown, BrandMenu },
  inject: ['setSelectedItem', 'selectedItems'],
  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchValue: '',
      brands: [],
      models: []
    }
  },
  computed: {
    options() {
      return this.selectedItems.brand.models || []
    }
  },
  watch: {
    'selectedItems.brand.models'(value) {
      this.models = value || []
      console.log({ models: value })
    },
    'selectedItems.brand'(value) {
      console.log({ brand: value })
    },
    'selectedItems.model'(value) {
      if (value === null) {
        this.searchValue = ''
      }
    }
  },
  methods: {
    onClick({ setOpen }) {
      setOpen?.(true)
    },
    handleSearchChange(event) {
      this.searchValue = event.target.value

      if (!this.searchValue) {
        this.models = this.options
      } else {
        this.models = this.options.filter(item => item.label.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
    },
    selectBrand($event, setOpen) {
      this.setSelectedItem({ model: this.selectedItems.selectedBrand })
      this.searchValue = `${this.selectedItems.selectedBrand?.label || ''}`
      setOpen?.(false)
    },

    clear(setOpen) {
      this.setSelectedItem({ model: null })
      this.searchValue = null
      setOpen?.(false)
      this.models = this.options
    },
    onClose() {
      if (this.selectedItems.model) {
        this.searchValue = `${this.selectedItems.model.label}`
        this.models = this.options
      }
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <Teleport v-if="isOpen" to="body">
    <form @submit.prevent="submitSearch" class="search-modal">
      <div class="content">
        <div class="title-box">
          <p>{{ t.searchVehicles }}</p>
          <div class="icon" @click="$emit('close')">
            <IconClose />
          </div>
        </div>
        <div class="form-box">
          <!-- <label>{{ t.makeAndModel }}</label>
          <SelectBrand :options="brandOptions" :placeholder="t.selectMakeAndModel" /> -->
          <label>{{ t.make }}</label>
          <SelectMake :options="brandOptions" :placeholder="t.selectMake" />
          <label>{{ t.model }}</label>
          <SelectModel :placeholder="t.selectModel" />
          <label>{{ t.year }}</label>
          <SelectYear :options="yearOptions" :placeholder="t.selectYear" />
          <label>{{ t.mileage }}</label>
          <SelectMileage :options="mileageOptions" :placeholder="t.selectMileage" />
          <label>{{ t.price }}</label>
          <SelectPrice :options="priceOptions" :placeholder="t.selectPrice" />
          <div class="tags">
            <div v-for="tag in tags" :key="tag.id">{{ tag.name }} <span @click="removeTag(tag.field)">&#x00d7;</span></div>
          </div>
        </div>
      </div>
      <div class="footer">
        <button class="lightPrimaryButton">
          <IconSearch />
          {{ languageResources?.Common?.search }} {{ getFilterCount }}
        </button>
      </div>
    </form>
  </Teleport>
</template>

<script>
import IconSearch from '@/assets/icons/search.svg'
import IconClose from '@/assets/icons/close.svg'
import SelectYear from '@/components/Home/Hero/SelectYear.vue'
import filterQueryMixin from '@/lib/filterQueryMixin.js'
import SelectMake from '@/components/Home/Hero/SelectMake.vue'
import SelectModel from '@/components/Home/Hero/SelectModel.vue'
import SelectMileage from '@/components/Home/Hero/SelectMileage.vue'
import SelectPrice from '@/components/Home/Hero/SelectPrice.vue'
import { langMixin } from '@/lang'

export default {
  name: 'SearchModal',
  components: {
    IconSearch,
    IconClose,
    SelectYear,
    SelectMileage,
    SelectPrice,
    SelectMake,
    SelectModel
  },
  mixins: [filterQueryMixin, langMixin('Search')],
  inject: ['selectedItems', 'filteredListingCount'],
  props: {
    isOpen: {
      type: Boolean,
      default: false
    }
  },
  emits: ['close', 'submit'],
  watch: {
    selectedItems() {
      console.log('day ', this.selectedItems.brand?.models)
    }
  },
  mounted() {
    document.body.classList.add('modal-open')
  },
  beforeUnmount() {
    document.body.classList.remove('modal-open')
  }
}
</script>

<style lang="scss">
.search-modal {
  position: fixed;
  inset: 0;
  background-color: var(--background);
  z-index: 10;
  display: flex;
  flex-direction: column;

  .content {
    flex: 1;
    padding: 1.875rem;
    overflow: auto;
    max-height: calc(100vh - 6.25rem);

    &::-webkit-scrollbar {
      display: none;
    }

    .title-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;

      p {
        font-family: var(--heading-font);
        font-weight: 700;
        font-size: 1.5rem;
      }

      .icon {
        width: 2.5rem;
        height: 2.5rem;
        background-color: var(--neutral-30);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
      }
    }

    .form-box {
      padding: 1.5rem 1rem;
      border: 1px solid var(--neutral-30);
      border-radius: 0.625rem;
      display: flex;
      flex-direction: column;
      gap: 1.25rem;

      > label {
        font-weight: 700;
        color: var(--black);
      }

      > .two-cols {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1.25rem;
      }

      .tags {
        display: flex;
        gap: 0.625rem;
        flex-wrap: wrap;

        > * {
          background-color: var(--primary);
          border-radius: 1.25rem;
          padding: 0.3rem 0.625rem;
          display: flex;
          align-items: center;
          font-size: 0.75rem;
          font-weight: 600;
          gap: 0.25rem;

          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  .footer {
    padding: 1.25rem 1rem;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0px -6px 6px rgba(0, 0, 0, 0.06);

    .lightPrimaryButton {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      width: 100%;
      padding: 1rem;
    }
  }
}

.modal-open {
  overflow: hidden;
}
</style>

<template>
  <DropdownComponent @click="onClick" data-test-id="search_by_model" @close="onClose">
    <div class="select-field">
      <input class="input-field" :value="searchValue" @input="handleSearchChange" @blur="onBlur" :placeholder="placeholder" />
      <IconChevronDown />
    </div>
    <template #content="slotProps" v-if="brands.length > 0">
      <BrandMenu :type="'make'" @select-brand="selectBrand($event, slotProps.setOpen)" :options="brands" @clear="clear(slotProps.setOpen)" />
    </template>
  </DropdownComponent>
</template>

<script>
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import DropdownComponent from '@/components/Dropdown.vue'
import BrandMenu from '@/components/Home/Hero/BrandMenu.vue'

export default {
  name: 'SelectMake',
  components: { DropdownComponent, IconChevronDown, BrandMenu },
  inject: ['setSelectedItem', 'selectedItems'],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchValue: '',
      brands: []
    }
  },
  watch: {
    options(value) {
      this.brands = value
    }
    // 'selectedItems.model'(value) {
    //   if (value === null) {
    //     this.searchValue = ''
    //   }
    // }
  },
  methods: {
    onClick({ setOpen }) {
      setOpen?.(true)
    },
    handleSearchChange(event) {
      this.searchValue = event.target.value

      if (!this.searchValue) {
        this.brands = this.options
      } else {
        this.brands = this.options.filter(item => item.label.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
    },
    selectBrand(event, setOpen) {
      this.setSelectedItem({ brand: this.selectedItems.selectedBrand, model: null })
      this.searchValue = `${this.selectedItems.selectedBrand?.label || ''}`
      setOpen?.(false)
    },
    clear(setOpen) {
      this.setSelectedItem({ model: null, brand: null })
      this.searchValue = null
      setOpen?.(false)
      this.brands = this.options
    },
    onClose() {
      if (this.selectedItems.model) {
        this.searchValue = `${this.selectedItems.brand?.label}`
        this.brands = this.options
      }
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <section id="frequently-asked-questions" class="faq vertical-padding">
    <h2 class="title">{{ t.faq }}</h2>
    <div class="accordions">
      <Accordion :key="item.question" v-for="item in faqItems" :title="item.question">
        {{ item.anwser }}
        <template #icon>
          <ArrowRightCircle />
        </template>
      </Accordion>
    </div>
  </section>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import { langMixin } from '@/lang'
import ArrowRightCircle from '@/assets/icons/arrow-right-circle.svg'

export default {
  name: 'FeaturedCars',
  components: { Accordion, ArrowRightCircle },
  mixins: [langMixin('Home')],
  computed: {
    faqItems() {
      return JSON.parse(this.t.faqItems || '[]')
    }
  }
}
</script>
<style lang="scss">
section.faq {
  background: url('/assets/squiggly4.svg') no-repeat right -8.75rem;

  @media screen and (max-width: 36rem) {
    background: url('/assets/squiggly4.svg') no-repeat 0 -10%;
  }

  padding: {
    left: var(--content-side-padding);
    right: var(--content-side-padding);
  }

  .title {
    margin-bottom: 3.75rem;
  }

  .accordions {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
}
</style>

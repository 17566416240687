<template>
  <div class="profile">
    <div class="profile-panel">
      <!-- <div class="avatar-box">
        <div class="avatar">
          <img src="https://picsum.photos/200/300" alt="avatar" loading="lazy" />
        </div>
        <div class="user-box">
          <p class="username">{{ currentUser?.name }}</p>
          <p class="description">Registered since March 2023</p>
        </div>
      </div> -->
      <nav>
        <template :key="route.name" v-for="route in routes">
          <RouterLink v-if="!route.hidden" :data-test-id="route.name" :to="route.path">
            <component :is="route.icon" />
            <span>{{ t[route.name] }}</span>
          </RouterLink>
        </template>

        <button class="logout" @click="logout" v-if="currentUser">
          <LogoutIcon />
          <span>{{ t.logout }}</span>
        </button>
      </nav>
    </div>
    <div class="profileSection">
      <RouterView />
    </div>
  </div>
</template>

<script>
import { RouterView, RouterLink } from 'vue-router'
import UserIcon from '@/assets/icons/user.svg'
import CartIcon from '@/assets/icons/cart.svg'
import CallIcon from '@/assets/icons/call.svg'
import CarIcon from '@/assets/icons/car.svg'
import HeartIcon from '@/assets/icons/heart.svg'
import FolderIcon from '@/assets/icons/folder.svg'
import LogoutIcon from '@/assets/icons/logout.svg'
import { langMixin } from '@/lang.js'

export default {
  name: 'ProfilePage',
  components: {
    RouterView,
    RouterLink,
    LogoutIcon,
    CartIcon,
    CarIcon,
    CallIcon
  },
  mixins: [langMixin('Profile')],
  inject: ['currentUser'],
  computed: {
    routes() {
      return [
        {
          icon: UserIcon,
          name: 'my_account',
          path: '/me/account',
          hidden: !this.currentUser
        },
        {
          icon: HeartIcon,
          name: 'favorites',
          path: '/me/favorites'
        },
        {
          icon: FolderIcon,
          name: 'saved_searches',
          path: '/me/saved-searches'
        },
        {
          icon: CartIcon,
          name: 'my_orders',
          path: '/me/my-orders'
        },
        {
          icon: CallIcon,
          name: 'my_leads',
          path: '/me/my-leads'
        },
        {
          icon: CarIcon,
          name: 'test_drives',
          path: '/me/test-drives'
        }
      ]
    }
  },
  methods: {
    logout() {
      window.seezSdk.showLogout()
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/base.scss';

.profile-panel {
  border-inline-end: 1px solid #efefef;
  padding-inline-end: 1em;
  padding: 0 110px;
  padding-top: 70px;
  background-color: var(--dark-background);
  display: none;
  color: var(--background);

  @media screen and (min-width: 53.438rem) {
    display: flex;
    flex-direction: column;
  }

  .avatar-box {
    display: flex;
    margin-bottom: 30px;
    color: var(--background);
    gap: 1rem;

    .user-box {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .username {
      font-size: 1.25rem;
      font-weight: 700;
      margin-bottom: 0.25rem;
      font-family: var(--heading-font);
    }

    .description {
      font-weight: 400;
      font-size: 0.875rem;
    }

    .avatar {
      img {
        border-radius: 50%;
        width: 58px;
        height: 58px;
        object-fit: cover;
      }
    }
  }

  > nav {
    z-index: 2;

    @media screen and (min-width: 53.438rem) {
      display: flex;
      flex-direction: column;
      flex: 1;
      position: initial;
      border-radius: 0;
      z-index: 0;
      box-shadow: none;
      max-width: none;
      margin: 0;
    }

    > a {
      color: var(--background);
      position: relative;
      border-radius: 8px;
      display: flex;
      gap: 1em;
      align-items: center;
      margin: 0.3rem;
      font-size: 1.125rem;
      font-weight: 500;

      @media screen and (min-width: 53.438rem) {
        padding: 1em 1.5em;
        margin: 0;
      }

      &.router-link-exact-active {
        background: var(--background);
        color: var(--font-color);
        font-weight: 700;
      }
    }

    > button.logout {
      position: absolute;
      bottom: 20px;
      border: none;
      background: none;
      text-align: start;
      cursor: pointer;
      color: var(--white);
      display: flex;
      gap: 1em;
      align-items: center;
      font-family: var(--font-family), sans-serif;
      font-size: 1.125rem;
      font-weight: 500;
      padding: 1rem;

      svg {
        margin-inline-start: 7px;
      }
    }

    > a,
    button {
      padding: 1rem;
    }
  }
}

.profile {
  position: relative;
  display: grid;
  grid-template-columns: 500px auto;
  text-align: start;
  height: calc(var(--vh, 1vh) * 100 - var(--header-height));

  overflow: hidden;

  > .profileSection {
    overflow: auto;
    background-color: #f5f6f7;
  }

  @media screen and (max-width: 75rem) {
    grid-template-columns: 400px auto;

    .profile-panel {
      padding: 50px 50px 0;
    }
  }

  @media screen and (max-width: 53.438rem) {
    grid-template-columns: 1fr;

    > .profileSection {
      padding-bottom: 0;
    }
  }
}

seez-sdk-active-order-cancellation .cancelOrderDialog {
  --highlight: var(--accent);
  --base-font: var(--base-font);

  .cta > button,
  .cta > button.preferred {
    border-radius: 0.5rem;
  }
}

seez-sdk-unavailable-listing-modal {
  .unavailableListingModal {
    font-family: var(--base-font);
    --base-font: var(--base-font);

    > svg {
      stroke: var(--accent);
    }

    > .buttonWrapper {
      > button:first-of-type {
        background-color: var(--accent);
        border-color: var(--accent);
        border-radius: 0.5rem;
        font-family: var(--base-font);
      }
    }
  }

  .carousel .listingCard {
    @include listingCard;
  }
}
</style>

<template>
  <DropdownComponent @click="onClick" data-test-id="search_by_price">
    <div class="select-field">
      <input class="input-field" :value="searchValue" @input="handleSearchChange" @blur="onBlur" :placeholder="placeholder" />
      <IconChevronDown />
    </div>
    <template #content="slotProps">
      <PriceMenu @select="select($event, slotProps.setOpen)" :options="prices" @clear="clear(slotProps.setOpen)" />
    </template>
  </DropdownComponent>
</template>

<script>
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import DropdownComponent from '@/components/Dropdown.vue'
import PriceMenu from '@/components/Home/Hero/PriceMenu.vue'

export default {
  name: 'SelectBrand',
  components: { DropdownComponent, IconChevronDown, PriceMenu },
  inject: ['setSelectedItem', 'selectedItems'],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    placeholder: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      searchValue: '',
      prices: []
    }
  },
  watch: {
    options(value) {
      this.prices = value
    },
    'selectedItems.price'(value) {
      if (value === null) {
        this.searchValue = ''
      }
    }
  },
  methods: {
    onClick({ setOpen }) {
      setOpen(true)
    },
    handleSearchChange(event) {
      this.searchValue = event.target.value

      if (!this.searchValue) {
        this.prices = this.options
      } else {
        this.prices = this.options.filter(item => item.label.toLowerCase().includes(this.searchValue.toLowerCase()))
      }
    },
    select(option, setOpen) {
      this.setSelectedItem({ price: option })
      this.searchValue = option.label
      setOpen(false)
    },
    clear(setOpen) {
      this.setSelectedItem({ price: null, priceType: 'retail' })
      this.searchValue = null
      setOpen(false)
    },
    onBlur() {
      if (this.selectedItems.price) {
        this.searchValue = this.selectedItems.price?.label
      }
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <div class="contact-content">
    <div class="contact-box">
      <IconBuilding />
      <address>
        <strong>{{ t.introAddress }}</strong>
        <span>{{ t.address }}</span>
      </address>
      <a class="direction" :href="t.directionLink" target="_blank" rel="noreferrer">
        {{ t.getDirections }}
        <IconExternalLink />
      </a>
    </div>
  </div>
</template>

<script>
import IconBuilding from '@/assets/icons/building.svg'
import IconExternalLink from '@/assets/icons/external-link.svg'
import { langMixin } from '@/lang'

export default {
  name: 'ContactUs',
  components: {
    IconBuilding,
    IconExternalLink
  },
  mixins: [langMixin('Home')]
}
</script>

<style lang="scss">
.contact-box {
  address {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    margin-bottom: 1rem;
    white-space: pre-line;
    font-style: normal;

    span {
      white-space: pre-line;
      line-height: 1.5rem;
    }
  }

  .direction {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    color: var(--accent);
    line-height: 1.5rem;
    font-weight: 500;
    text-decoration: underline;

    svg * {
      fill: var(--accent);
    }
  }
}
</style>

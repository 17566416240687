<template>
  <div class="dropdown-container" ref="wrapperRef" @click="onClick">
    <slot :set-open="setOpen" />

    <div v-if="isOpen" ref="dropdownRef" class="dropdown" @click.stop="clickOutSide">
      <slot name="content" :set-open="setOpen" />
    </div>
  </div>
</template>

<style lang="scss">
.dropdown-container {
  position: relative;
}

.dropdown {
  position: absolute;
  left: 0;
  right: 0;
}
</style>

<script>
export default {
  name: 'DropdownComponent',
  props: {
    dropdownGap: { type: String, default: '0.125rem' }
  },
  emits: ['click', 'close'],
  data() {
    return {
      isOpen: false
    }
  },
  watch: {
    'refs.dropdownRef'() {
      const ref = this.$refs.dropdownRef
      if (ref && this.isOpen) {
        const { height, width } = this.wrapperRef.getBoundingClientRect()
        ref.style.width = `${width}px`
        ref.style.top = `calc(${height}px + ${this.dropdownGap})`
        ref.style.zIndex = 999
      }
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutSide)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutSide)
  },
  methods: {
    setOpen(isOpen) {
      this.isOpen = isOpen
    },
    clickOutSide(e) {
      if (this.$refs.dropdownRef && this.$refs.wrapperRef && !this.$refs.wrapperRef.contains(e.target) && !this.$refs.dropdownRef.contains(e.target)) {
        this.isOpen = false
        this.$emit('close')
      }
    },
    onClick() {
      this.$emit('click', { setOpen: this.setOpen })
    }
  }
}
</script>

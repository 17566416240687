<template>
  <div class="accordion-container">
    <div :class="`accordion ${className}`">
      <div class="title-box" :data-show="showContent" @click="toggle">
        <p class="subtitle">{{ title }}</p>
        <div class="icon-box">
          <slot name="icon">
            <ChevronRight class="arrow" />
          </slot>
        </div>
      </div>

      <div :style="`height: ${contentHeight}px`" class="accordion-content">
        <div ref="contentRef" class="content-box">
          <p class="content-slot"><slot /></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronRight from '@/assets/icons/chevron-right.svg'

export default {
  name: 'AccordionComponent',
  components: { ChevronRight },
  props: {
    title: { type: String, default: '' },
    className: { type: String, default: '' }
  },
  data() {
    return {
      showContent: false,
      contentHeight: 0
    }
  },
  methods: {
    toggle() {
      this.showContent = !this.showContent
      const contentHeight = this.$refs.contentRef.offsetHeight

      if (this.showContent) {
        this.contentHeight = contentHeight
      } else {
        this.contentHeight = 0
      }
    }
  }
}
</script>

<style lang="scss">
.accordion-container {
  position: relative;
  display: flex;
  flex-direction: column;

  .accordion {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    background-color: var(--white);
    color: var(--background);
    border-radius: 1rem;
    color: var(--black);
    .title-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1.5rem 2rem;
      gap: 1rem;
      border: 2px solid rgba(95, 101, 103, 0.2);
      border-radius: 1rem;
      background-color: white;
      font-weight: 600;

      svg path {
        stroke: var(--background);
      }

      .icon-box {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &[data-show='true'] {
        border-end-start-radius: 0;
        border-end-end-radius: 0;
        .arrow {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .arrow {
    transform: rotate(90deg);
    transition-property: transform;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
  }

  .accordion-content {
    flex-shrink: 0;
    overflow: hidden;
    transition-property: height;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 250ms;
  }

  .content-box {
    padding: 1.5rem 2rem;
    border: 2px solid rgba(95, 101, 103, 0.2);
    border-width: 0 2px 2px;
    background: white;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
  }

  .content-slot {
    font-size: 1.25rem;
  }
}
</style>

<template>
  <main>
    <Hero />
    <FeaturedCars />
    <Benefits />
    <FinancingYourDream />
    <ShopCars />
    <Testimonials />
    <FAQ />
  </main>
  <FooterComponent />
</template>

<script>
import Hero from './Hero.vue'
import FeaturedCars from './FeaturedCars.vue'
import Benefits from './Benefits.vue'
import FinancingYourDream from './FinancingYourDream.vue'
import ShopCars from './ShopCars.vue'
import Testimonials from './Testimonials.vue'
import FAQ from './FAQ.vue'
import FooterComponent from '@/components/Footer/Footer.vue'

export default {
  components: {
    Hero,
    FeaturedCars,
    Benefits,
    FinancingYourDream,
    ShopCars,
    Testimonials,
    FAQ,
    FooterComponent
  }
}
</script>

<style lang="scss">
.vertical-padding {
  padding: {
    top: 5.5rem;
    bottom: 5.5rem;
  }

  @media (max-width: 36rem) {
    padding: {
      top: 3.5rem;
      bottom: 3.5rem;
    }
  }
}

.title {
  font-size: 2.25rem;
  font-weight: 700;
  font-family: var(--heading-font);
  color: var(--accent);

  @media (max-width: 36rem) {
    font-size: 1.75rem;
  }
}

.subtitle {
  line-height: 1.5rem;

  @media (min-width: 36rem) {
    font-size: 1.25rem;
  }
}
</style>

<template>
  <section class="shop-cars vertical-padding">
    <h2 class="title">{{ t.shopCarByType }}</h2>
    <!-- <p class="subtitle">{{ t.shopCarByTypeDesc }}</p> -->
    <Carousel ref="carouselRef" v-if="loaded">
      <CarTypeCard v-for="item in filteredBodyTypes" :key="item.name" :item="item" @click="goToSearch(item)" />
    </Carousel>
  </section>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import CarTypeCard from '@/components/Home/CarTypeCard.vue'
import { langMixin } from '@/lang'
import { querierMixin } from '@/lib/querierMixin'

export default {
  name: 'FeaturedCars',
  components: { Carousel, CarTypeCard },
  mixins: [querierMixin, langMixin('Home')],
  data() {
    return {
      bodies: [],
      fuels: [],
      loaded: false
    }
  },
  computed: {
    carTypes() {
      return JSON.parse(this.t.shopCarByTypeItems || '[]')
    },
    filteredBodyTypes() {
      if (!this.loaded) return this.carTypes

      return this.carTypes?.reduce((acc, item) => {
        let count = 0
        if (item.type === 'body') {
          count = this.bodies.find(body => body.name === item.name)?.count
        }

        if (item.type === 'fuel') {
          count = this.fuels.find(body => body.name === item.name)?.count
        }

        if (item.type === 'commercial') {
          count = item.fields.reduce((commercialAcc, commercialItem) => {
            if (commercialItem.type === 'body') {
              count = this.bodies.find(body => body.name === commercialItem.name)?.count
            }

            if (commercialItem.type === 'fuel') {
              count = this.fuels.find(body => body.name === commercialItem.name)?.count
            }

            return commercialAcc + (count || 0)
          }, 0)
        }

        if (!count) return acc

        return [...acc, { ...item, count }]
      }, [])
    }
  },
  mounted() {
    this.getBodiesCount()
  },
  methods: {
    goToSearch(item) {
      if (item.type === 'body') {
        const id = this.bodies.find(b => b.name === item.name)?.id
        this.$router.push({
          path: '/search',
          query: {
            'body-types': id
          }
        })
      }

      if (item.type === 'fuel') {
        const id = this.fuels.find(b => b.name === item.name)?.id
        this.$router.push({
          path: '/search',
          query: {
            'fuel-types': id
          }
        })
      }

      if (item.type === 'commercial') {
        const query = this.carTypes
          ?.find(item => item.type === 'commercial')
          ?.fields.reduce(
            (acc, item) => {
              if (item.type === 'body') {
                const id = this.bodies.find(b => b.name === item.name)?.id
                acc['body-types'].push(id)
              }

              if (item.type === 'fuel') {
                const id = this.fuels.find(b => b.name === item.name)?.id
                acc['fuel-types'].push(id)
              }

              return acc
            },
            { 'body-types': [], 'fuel-types': [] }
          )
        this.$router.push({
          path: '/search',
          query
        })
      }
    },
    async getBodiesCount() {
      const response = await this.queryApi('query filterOptionsWithCounters($filter: ListingFiltersInput){filterOptionsWithCounters(filter:$filter){bodies{id name count} fuels { id name count } }}', {
        filter: {
          prices: {
            type: 'retail'
          }
        }
      })

      this.bodies = response.filterOptionsWithCounters.bodies
      this.fuels = response.filterOptionsWithCounters.fuels
      this.loaded = true
    }
  }
}
</script>
<style lang="scss">
section.shop-cars {
  background: #fafafa;
  padding-inline-start: var(--content-side-padding);

  .title {
    margin-bottom: 2.5rem;
  }

  .subtitle {
    margin-bottom: 2.5rem;
    max-width: 57rem;
    color: var(--neutral-80);
  }

  .slider-controls {
    margin-top: 2.875rem;
    gap: 1.25rem;
  }
}
</style>

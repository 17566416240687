<template>
  <button ref="languageMenu" class="language-btn" @click="toggleLanguage">
    <div class="language-icon">
      <component :is="currentLangIcon" />
    </div>
    <span>{{ t[language] }}</span>
    <ul v-if="showLanguageDropdown" class="language-dropdown-list">
      <li :key="lang.name" v-for="lang in languages">
        <div @click="changeLanguage(lang.name)">
          <component :is="lang.icon" />
          {{ t[lang.name] }}
        </div>
      </li>
    </ul>
  </button>
</template>

<script>
import IconQatar from '@/assets/icons/qatar.svg'
import IconUK from '@/assets/icons/uk.svg'
import { langMixin } from '@/lang'

export default {
  name: 'LanguageDropdown',
  mixins: [langMixin('Common')],
  inject: ['currentUser'],
  data() {
    return { showLanguageDropdown: false }
  },
  computed: {
    currentLangIcon() {
      return this.languages.find(item => item.name === this.language)?.icon
    },
    languages() {
      return [
        {
          icon: IconQatar,
          name: 'ar'
        },
        {
          icon: IconUK,
          name: 'en'
        }
      ]
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOutsideUserDropdown)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.clickOutsideUserDropdown)
  },
  methods: {
    clickOutsideUserDropdown(event) {
      if (!this.$refs.languageMenu?.contains(event.target)) {
        this.showLanguageDropdown = false
      }
    },
    async changeLanguage(lang) {
      localStorage.setItem('language', lang)
      if (this.currentUser) {
        await window.seezSdk.queryApi(`mutation updateMe { updateMe(details: {language: "${lang}"}) { language }}`)
      }
      window.location.reload()
    },
    toggleLanguage() {
      this.showLanguageDropdown = !this.showLanguageDropdown
    }
  }
}
</script>

<style lang="scss">
.language-btn {
  border: none;
  background: none;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0.5rem;
}

.language-dropdown-list {
  z-index: 3;
  position: absolute;
  inset-inline-end: 0;
  inset-block-start: 4rem;
  margin: 0;
  padding: 0.5rem 0.8rem;
  list-style-type: none;
  border-radius: 0.25em;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &:lang(:ar) {
    left: 0;
    right: unset;
  }

  background-color: var(--background);
  border: 1px solid rgba(0, 0, 0, 0.3);

  li div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;

    &:hover {
      background-color: var(--dark-background);
      color: var(--background);
    }

    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
}

.language-icon {
  svg {
    width: 1.75rem;
    height: 1.75rem;
  }
}
</style>

<template>
  <section class="hero">
    <Carousel :page-count="heroCards?.length">
      <div v-for="item in heroCards" :key="item.id" class="carousel-item" :style="{ backgroundImage: `url('/assets/squiggly2.svg'), url(${item.imageUrl})` }">
        <div class="slogan">
          <h1>{{ item.title }}</h1>
        </div>
      </div>
    </Carousel>
    <div class="search-box-mobile" @click="showSearchModal = true">
      <div class="select-field">
        <div class="label-box">
          <label>{{ tSearch?.searchVehicles }}</label>
          <span>{{ tSearch?.searchFields }}</span>
        </div>
        <IconChevronDown />
      </div>
      <button class="lightPrimaryButton" @click="goToSearch">
        <IconSearch />
        {{ tSearch?.allCars }} {{ getFilterCount }}
      </button>
    </div>
    <div class="search-box">
      <div class="selects">
        <label>
          <span>{{ tSearch?.make }}</span>
          <SelectMake :options="brandOptions" :placeholder="tSearch?.selectMake" />
        </label>
        <label :data-disabled="!selectedItems.brand">
          <span>{{ tSearch?.model }}</span>
          <SelectModel :placeholder="tSearch?.selectModel" />
        </label>
        <label>
          <span>{{ tSearch?.year }}</span>
          <SelectYear :options="yearOptions" :placeholder="tSearch?.selectYear" />
        </label>
        <label>
          <span>{{ tSearch?.mileage }}</span>
          <SelectMileage :options="mileageOptions" :placeholder="tSearch?.selectMileage" />
        </label>
        <label>
          <span>{{ tSearch?.price }}</span>
          <SelectPrice :options="priceOptions" :placeholder="tSearch?.selectPrice" />
        </label>
      </div>
      <div class="button-box">
        <button class="lightPrimaryButton" @click="submitSearch" data-test-id="hero_search_button">
          <IconSearch />
          {{ languageResources?.Common?.search }} {{ getFilterCount }}
        </button>
      </div>
    </div>
    <SearchModal v-if="showSearchModal" :is-open="showSearchModal" @close="showSearchModal = false" />
  </section>
</template>
<script>
import { langMixin } from '@/lang.js'
import Carousel from '@/components/Home/Carousel.vue'
import IconSearch from '@/assets/icons/search.svg'
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import SearchModal from '@/components/Home/Hero/SearchModal.vue'
import filterQueryMixin from '@/lib/filterQueryMixin.js'
import SelectModel from '@/components/Home/Hero/SelectModel.vue'
import SelectMake from '@/components/Home/Hero/SelectMake.vue'
import SelectMileage from '@/components/Home/Hero/SelectMileage.vue'
import SelectPrice from '@/components/Home/Hero/SelectPrice.vue'
import SelectYear from '@/components/Home/Hero/SelectYear.vue'

export default {
  name: 'HeroSection',
  components: { Carousel, IconSearch, IconChevronDown, SearchModal, SelectMake, SelectMileage, SelectPrice, SelectYear, SelectModel },
  mixins: [langMixin('Home'), filterQueryMixin],
  data() {
    return {
      showSearchModal: false
    }
  },
  computed: {
    tSearch() {
      return this.languageResources?.Search
    },
    heroCards() {
      return JSON.parse(this.t.heroCards || '[]')
    }
  },
  methods: {
    goToSearch(event) {
      this.addSearchBtnDataLayer()
      this.$router.push({ path: '/search' })
      event.stopPropagation()
    }
  }
}
</script>
<style lang="scss">
section.hero {
  margin-top: calc(0px - var(--header-height));
  position: relative;
}

.search-box {
  display: flex;
  position: absolute;
  bottom: 6.125rem;
  background-color: var(--background);
  left: 0;
  right: 0;
  margin: 0 calc(var(--content-side-padding) + 2rem);
  border-radius: 1rem;
  border: 2px solid var(--neutral-30);
  z-index: 2;

  .selects {
    flex: 1;
    display: flex;
    align-items: center;

    > label {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      cursor: pointer;
      gap: 0.5rem;

      > span {
        font-weight: 700;
        color: var(--black);
        font-size: 0.875rem;
        padding: 0 2rem;
      }

      &:not(:last-child) {
        border-inline-end: 1px solid var(--neutral-30);
      }

      &[data-disabled='true'] {
        background-color: var(--neutral-30);
        cursor: not-allowed;

        input {
          background-color: var(--neutral-30);
          cursor: not-allowed;
        }
      }
    }

    .select-field {
      input {
        border: none;
        outline: none;
        padding: 0 2rem;
        padding-bottom: 1rem;
      }

      svg {
        display: none;
      }
    }
  }

  .button-box {
    padding: 0.5rem 0.75rem;
    display: flex;
    align-items: center;

    .lightPrimaryButton {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      width: 100%;
      padding: 1rem 0;
      min-width: 12rem;
    }
  }
}

.carousel-item {
  min-width: 100%;
  height: 100vh;
  position: relative;
  color: var(--background);
  background-repeat: no-repeat, no-repeat;
  background-size: 110%, cover;
  background-position:
    72% 0%,
    center center;

  @media screen and (max-width: 36rem) {
    background-size: auto, cover;
    background-position:
      100% 45%,
      center;
  }

  padding: 0 var(--content-side-padding);

  .slogan {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 5rem;
    left: 0;
    right: 0;
    padding: 0 calc(var(--content-side-padding) + 5rem);
    text-align: center;
    z-index: 2;

    h1 {
      font-size: 2.7rem;
      line-height: 4.5rem;
      font-family: var(--heading-font);
    }
  }

  &::after {
    position: absolute;
    content: '';
    inset: 0;
    background: rgba(0, 0, 0, 0.15);
  }
}

.carousel-pagination {
  display: none;
}

.carousel-controls {
  padding: 0 var(--content-side-padding);
}

.search-box-mobile {
  display: none;
  z-index: 2;
}

@media screen and (max-width: 75rem) {
  .search-box {
    display: none;
  }

  .carousel-item {
    .slogan {
      padding: 0 1.5rem;

      h1 {
        font-size: 1.5rem;
        line-height: 2.5rem;
      }
    }
  }

  .search-box-mobile {
    display: flex;
    flex-direction: column;
    background-color: var(--background);
    border-radius: 0.5rem;
    border: 1px solid var(--neutral-30);
    padding: 1rem;
    position: absolute;
    bottom: 4.5rem;
    left: 0;
    margin: 0 1.5rem;
    right: 0;
    gap: 1rem;

    .select-field {
      display: flex;
      justify-content: space-between;
      color: var(--font-color);
      align-items: center;

      .label-box {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        label {
          font-weight: 700;
        }

        span {
          font-size: 0.875rem;
          color: var(--neutral-50);
        }
      }
    }

    .lightPrimaryButton {
      padding: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .carousel-controls {
    display: none;
  }

  .carousel-pagination {
    display: block;
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>

<template>
  <div>
    <div class="bar">
      <div class="progress" :style="{ width: percent + '%' }" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProgressBar',
  props: {
    percent: {
      type: Number,
      default: 0
    }
  }
}
</script>

<style lang="scss">
.bar {
  width: 5.375rem;
  height: 0.625rem;
  background-color: #e4e4e4;
  border-radius: 2.5rem;

  .progress {
    background-color: var(--accent);
    border-radius: 2.5rem;
    height: 0.625rem;
  }
}
</style>

<template>
  <div v-bind="$attrs" class="suggested-search-item" @click="$emit('click', $event)">
    <div class="content-box">
      <slot name="icon" />
      <div class="right-box">
        <p>{{ label }}</p>
        <span v-if="count">{{ count }} {{ t.vehicles }}</span>
      </div>
    </div>
    <IconChevronDown class="chevron-icon" v-if="showArrowIcon" />
  </div>
  <slot name="subItems" />
</template>

<style lang="scss">
.suggested-search-item {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: var(--neutral-30);
  }

  .content-box {
    display: flex;
    gap: 0.875rem;
    align-items: center;
  }

  .right-box {
    display: flex;
    flex-direction: column;
    padding: 0.25rem;
    min-height: 50%;

    > p {
      margin-bottom: 0;
      font-weight: 500;
    }

    > span {
      color: var(--neutral-50);
      font-size: 0.875rem;
    }
  }

  .chevron-icon {
    transition: transform 0.25s ease-out;
    transform: rotate(-90deg);
  }
}
</style>
<script>
import IconChevronDown from '@/assets/icons/chevron-down.svg'
import { langMixin } from '@/lang'

export default {
  components: { IconChevronDown },
  mixins: [langMixin('Home')],
  props: {
    label: { type: String, default: null },
    count: { type: Number, default: 0 },
    showArrowIcon: { type: Boolean, default: false }
  },
  emits: ['click']
}
</script>

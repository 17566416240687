<template>
  <footer>
    <section class="vertical-padding">
      <div>
        <div class="nav-mobile">
          <Accordion :title="t.location">
            <ContactUs />
          </Accordion>
          <Accordion :title="t.otherEnquiries">
            <OtherContent />
          </Accordion>
          <Accordion :title="t.exploreAAM">
            <Explore />
          </Accordion>
        </div>
        <div class="nav-desktop">
          <div>
            <p class="subtitle">{{ t.location }}</p>
            <ContactUs />
          </div>
          <div>
            <p class="subtitle">{{ t.otherEnquiries }}</p>
            <OtherContent />
          </div>
          <div>
            <p class="subtitle explore-aam">{{ t.exploreAAM }}</p>
            <Explore />
          </div>
        </div>
        <div class="socials">
          <a href="https://www.facebook.com/profile.php?id=100086172994691">
            <IconFacebook />
          </a>
          <a href="https://www.instagram.com/aampreowned/">
            <IconInstagram />
          </a>
          <a href="https://x.com/AAMPREOWNED">
            <IconX />
          </a>
          <a href="https://www.youtube.com/channel/UC1DBE9gW353bk4lnS5uqYwg">
            <IconYoutube />
          </a>
        </div>
      </div>
    </section>
    <section>
      <RouterLink active-class="active" to="/" :title="languageResources?.Common?.aam">
        <AAMLogoFlipped class="aamLogo ar" />
        <AAMLogoMobileFlipped class="ar aamLogoMobile" />
        <AAMLogo class="aamLogo en" />
        <AAMLogoMobile class="en aamLogoMobile" />
      </RouterLink>
      <div class="nav">
        <div class="termPolicy">
          <a href="/privacy" target="_blank">{{ t.privacyPolicy }}</a>
          <a href="/terms" target="_blank">{{ t.termsCondition }}</a>
        </div>
        <div class="rights">
          <div>
            <p>2023 © All Rights Reserved -</p>
            <p>Al Abdulghani Motors</p>
          </div>
          <LanguageDropdown class="footer-lang-btn" />
        </div>
      </div>
    </section>
  </footer>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import IconFacebook from '@/assets/icons/facebook.svg'
import IconInstagram from '@/assets/icons/instagram.svg'
import IconYoutube from '@/assets/icons/youtube.svg'
import IconX from '@/assets/icons/x.svg'
import ContactUs from './ContactUs.vue'
import Explore from './Explore.vue'
import OtherContent from './Other.vue'
import { langMixin } from '@/lang.js'
import { RouterLink } from 'vue-router'
import AAMLogo from '@/assets/aam-logo.svg'
import AAMLogoFlipped from '@/assets/aam-logo-flipped.svg'
import AAMLogoMobile from '@/assets/mobile-logo.svg'
import AAMLogoMobileFlipped from '@/assets/mobile-logo-flipped.svg'
import LanguageDropdown from '@/components/Header/LanguageDropdown.vue'

export default {
  name: 'FooterComponent',
  components: { IconFacebook, IconInstagram, IconYoutube, IconX, ContactUs, Explore, OtherContent, Accordion, RouterLink, AAMLogo, LanguageDropdown, AAMLogoFlipped, AAMLogoMobile, AAMLogoMobileFlipped },
  mixins: [langMixin('Home')],
  data() {
    return {
      isMobile: false,
      showLanguageDropdown: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.checkDeviceSize)
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkDeviceSize)
  },
  methods: {
    checkDeviceSize() {
      this.isMobile = window.innerWidth < 768
    }
  }
}
</script>

<style lang="scss">
footer {
  background-color: #d6d2c4;

  section {
    padding: {
      left: var(--content-side-padding);
      right: var(--content-side-padding);
    }
  }

  section:first-child > div {
    color: var(--neutral-90);
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    position: relative;

    .nav-desktop,
    .nav-mobile {
      .phone-box,
      .contact-content,
      .other-content,
      .explore-content {
        padding: 1rem 0;
      }

      .subtitle {
        font-weight: 500;
      }
    }

    .explore-aam {
      max-width: 55%;
      padding-inline-end: 1rem;
    }

    > .nav-desktop {
      display: none;
    }

    > .nav-mobile {
      display: flex;
      flex-direction: column;
      gap: 2.5rem;

      .accordion-container {
        .accordion {
          color: var(--neutral-90);
          background-color: transparent;
          &[data-show='true'].title-box {
            border-bottom: none;
          }

          svg path {
            stroke: var(--neutral-90);
          }

          .title-box,
          .content-box {
            border: none;
            background-color: transparent;
          }
        }
      }

      .accordion-container .accordion .title-box {
        padding: 0;

        &[data-show='true'] {
          border-bottom: none;
        }
      }

      .accordion-container .accordion .content-box {
        padding: 0;
        .content-slot {
          font-size: 1rem;
        }
      }
    }

    .socials {
      a {
        display: flex;
      }

      display: flex;
      align-items: center;
      gap: 1.5rem;
    }

    @media screen and (min-width: 64rem) {
      > .nav-mobile {
        display: none;
      }

      flex-direction: row;

      > .nav-desktop {
        display: flex;
        width: 100%;

        > div {
          flex: 1;
        }
      }

      .socials {
        position: absolute;
        right: 0;
        top: 0;

        html[dir='rtl'] & {
          left: 0;
          right: unset;
        }
      }

      .subtitle {
        margin-bottom: 3.75rem;
      }
    }

    a {
      color: var(--neutral-90);
    }
  }

  section:nth-child(2) {
    padding: {
      top: 1.5rem;
      bottom: 1.5rem;
    }
    background-color: var(--dark-background);
    color: var(--background);
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .aamLogo {
      fill: var(--background);
      @media screen and (max-width: 48rem) {
        display: none;
        height: 0;
      }
    }
    .aamLogoMobile {
      display: none;
      fill: var(--background);
      @media screen and (max-width: 48rem) {
        display: block;
      }
    }
    .ar {
      &:lang(en) {
        display: none;
      }
    }
    .en {
      &:lang(ar) {
        display: none;
      }
    }

    .nav {
      display: flex;
      flex-direction: column;
      .termPolicy {
        display: flex;
        gap: 1rem;
        @media screen and (min-width: 64rem) {
          gap: 2.5rem;
        }
      }
      gap: 1rem;

      a {
        color: white;
      }

      .rights {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    .footer-lang-btn {
      span {
        color: var(--background);
      }
      .language-dropdown-list {
        inset-inline-end: 0;
        bottom: 4rem;
        inset-block-start: unset;
      }
    }

    @media screen and (min-width: 64rem) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      font-size: 0.875rem;

      .nav {
        flex-direction: row;
        align-items: center;
      }

      .rights > div {
        display: flex;
        gap: 0.25rem;
      }

      .rights,
      .nav {
        gap: 2.5rem;
      }
    }
  }
}
</style>

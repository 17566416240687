import { querierMixin } from '@/lib/querierMixin'
import { computed } from 'vue'

const filterQueryMixin = {
  mixins: [querierMixin],
  provide() {
    return {
      filteredListingCount: computed(() => this.filteredListingCount),
      selectedItems: computed(() => this.selectedItems),
      setSelectedItem: item => {
        this.selectedItems = { ...this.selectedItems, ...item }
      }
    }
  },
  data() {
    return {
      filteredListingCount: null,
      selectedItems: {
        priceType: 'retail'
      },
      brandOptions: [],
      yearOptions: [],
      currency: null
    }
  },
  computed: {
    getFilterCount() {
      return this.filteredListingCount != null ? `(${this.filteredListingCount})` : ''
    },
    priceOptions() {
      let options = []

      if (this.selectedItems.priceType === 'retail') {
        options = [
          [undefined, 50000],
          [50000, 100000],
          [100000, 200000],
          [200000, 300000],
          [300000, undefined]
        ]
      } else {
        options = [
          [500, 1500],
          [1501, 2500],
          [2501, 3500],
          [3501, 4500],
          [4501, 5500],
          [5501, 6000],
          [6001, 7500],
          [7501, 8500],
          [8501, 10000]
        ]
      }

      return options.map(option => {
        const [min, max] = option
        let label
        const filter = {
          priceType: this.selectedItems.priceType,
          priceMin: min,
          priceMax: max
        }

        if (!min) {
          label = this.languageResources?.Search?.lessThan?.replace('{value}', this.formatPrice({ value: max }, this.currency))
        } else if (!max) {
          label = this.languageResources?.Search?.above?.replace('{value}', this.formatPrice({ value: min }, this.currency))
        } else {
          label = this.languageResources?.Search?.between?.replace('{min}', this.formatPrice({ value: min }, this.currency)).replace('{max}', this.formatPrice({ value: max }, this.currency))
        }

        return {
          label,
          value: filter
        }
      })
    },
    mileageOptions() {
      const options = [3000, 20000, 40000, 70000, 100000, 150000]
      return options.map(kilometrageMax => {
        return {
          label: this.languageResources?.Search?.lessThanKm?.replace('{value}', kilometrageMax.toLocaleString()),
          value: { kilometrageMax }
        }
      })
    },
    priceTypeFilter() {
      return {
        prices: {
          type: this.selectedItems.priceType
        }
      }
    },
    tags() {
      const tags = []
      if (this.selectedItems.brand) {
        tags.push({
          field: 'brand',
          name: this.selectedItems.brand.label
        })
      }

      if (this.selectedItems.model) {
        tags.push({
          field: 'model',
          name: this.selectedItems.model.label
        })
      }

      if (this.selectedItems.year) {
        tags.push({
          field: 'year',
          name: this.selectedItems.year.label
        })
      }

      if (this.selectedItems.mileage) {
        tags.push({
          field: 'mileage',
          name: this.selectedItems.mileage.label
        })
      }

      if (this.selectedItems.price) {
        tags.push({
          field: 'price',
          name: this.selectedItems.price.label
        })
      }

      return tags
    }
  },
  mounted() {
    this.initCount()
    this.getBrandAndYearOptions()
  },
  watch: {
    async 'selectedItems.brand'() {
      this.getTotalCount()
    },
    async 'selectedItems.model'() {
      this.getTotalCount()
    },
    async 'selectedItems.year'() {
      this.getTotalCount()
    },
    async 'selectedItems.mileage'() {
      this.getTotalCount()
    },
    async 'selectedItems.price'() {
      this.getTotalCount()
    }
  },
  methods: {
    async getTotalCount() {
      const filter = this.generateFilter()
      this.filteredListingCount = await this.getFilteredListingCount(filter)
    },
    generateFilter() {
      const filter = {}
      const value = this.selectedItems

      if (value.brand) {
        Object.assign(filter, {
          brands: [Number(value.brand?.value)]
        })
      }

      if (value.model) {
        Object.assign(filter, {
          models: [Number(value.model.value)]
        })
      }

      if (value.mileage) {
        Object.assign(filter, {
          kilometrageMax: Number(value.mileage.value.kilometrageMax)
        })
      }

      if (value.year) {
        Object.assign(filter, {
          yearMin: Number(value.year.value),
          yearMax: Number(value.year.value)
        })
      }

      if (value.price) {
        Object.assign(filter, {
          prices: {
            minPrice: value.price.value.priceMin,
            maxPrice: value.price.value.priceMax
          }
        })
      }

      if (value.priceType) {
        Object.assign(filter, {
          prices: {
            type: value.priceType,
            ...filter.prices
          }
        })
      }

      return filter
    },
    async getBrandAndYearOptions() {
      const response = await this.queryApi(
        ` query filterOptionsWithCounters($filter: ListingFiltersInput) {
          filterOptionsWithCounters(filter: $filter) {
            brands {
              id
              name
              count
            }
            families {
              id
              name
              brandid
              count
            }
            models {
              id
              name
              familyid
              count
            }
            years {
              year
              count
            }
            currencies {
              name
              count
            }
          }
        }
      `,
        { filter: this.priceTypeFilter }
      )

      const { brands, families, years, currencies, models } = response.filterOptionsWithCounters
      this.brandOptions = brands?.map(item => {
        const familiesIds = families?.filter(f => f.brandid === item.id).map(f => f.id)
        return {
          label: item.name,
          value: item.id,
          count: item.count,
          models: models?.filter(m => familiesIds.includes(m.familyid)).map(m => ({ value: m.id, label: m.name, count: m.count }))
        }
      })

      this.yearOptions = years?.map(item => ({ label: item.year?.toString(), value: item.year }))

      this.currency = currencies?.[0].name
    },
    async initCount() {
      this.filteredListingCount = await this.getFilteredListingCount(this.priceTypeFilter)
    },
    async getFilteredListingCount(filter) {
      const response = await this.queryApi('query filterListingTotal($filter:ListingFiltersInput) {listings(filter:$filter) {pageInfo {total}}}', {
        filter
      })

      return response?.listings?.pageInfo?.total
    },
    removeTag(field) {
      if (field) {
        if (field === 'brand') {
          this.selectedItems = { ...this.selectedItems, brand: null, model: null }
        } else {
          this.selectedItems = { ...this.selectedItems, [field]: null }
        }
      }
    },
    addSearchBtnDataLayer() {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'search_cars',
        formType: 'Search for a car',
        formPosition: 'Homepage',
        formID: 'searchCars'
      })
    },
    submitSearch() {
      this.addSearchBtnDataLayer()

      const query = {
        ...(this.selectedItems.mileage && { 'kilometrage-max': this.selectedItems.mileage.value.kilometrageMax }),
        ...(this.selectedItems.year && { 'year-min': this.selectedItems.year.value, 'year-max': this.selectedItems.year.value }),
        ...(this.selectedItems.price?.value?.priceMin && { 'price-min': this.selectedItems.price?.value?.priceMin }),
        ...(this.selectedItems.price?.value?.priceMax && { 'price-max': this.selectedItems.price?.value?.priceMax }),
        ...(this.selectedItems.priceType === 'emi' && { 'price-type': 'emi' })
      }

      let path = '/search'
      if (this.selectedItems.brand) {
        path += `/${this.$root.slugify(this.selectedItems.brand.label)}`
      }
      if (this.selectedItems.model) {
        path += `/${this.$root.slugify(this.selectedItems.model.label)}`
      }

      this.$router.push({ path, query })
    }
  }
}

export default filterQueryMixin

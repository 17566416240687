<template>
  <div class="select-menu">
    <div v-if="options.length > 0" :key="null" class="clear" @click="onClear">
      <span>{{ t.clear }}</span>
    </div>
    <SuggestedSearchItem class="select-item" v-for="item in options" :key="item.label" :label="item.label" :count="item.count" @click="onClickBrand($event, item)">
      <template #icon>
        <img v-if="type === `make`" :src="`${getMarketPlaceUrl}/brands/${slugify(item.label)}.webp`" alt="branch-logo" class="image" loading="lazy" />
        <img v-else :src="`${getMarketPlaceUrl}/brands/${slugify(selectedItems.brand.label)}.webp`" alt="branch-logo" class="image" loading="lazy" />
      </template>
    </SuggestedSearchItem>
  </div>
</template>

<script>
import SuggestedSearchItem from '@/components/Home/Hero/SuggestedSearchItem.vue'
import { slugify } from '@/lib/utils'
import { langMixin } from '@/lang'

export default {
  name: 'BrandMenu',
  components: { SuggestedSearchItem },
  mixins: [langMixin('Home')],
  inject: ['selectedItems', 'setSelectedItem'],
  props: {
    options: {
      type: Array,
      default() {
        return []
      }
    },
    type: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['selectModel', 'clear', 'selectBrand'],
  computed: {
    getMarketPlaceUrl() {
      return import.meta.env.VITE_MARKET_PLACE
    }
  },
  methods: {
    slugify,
    onClickBrand(event, option) {
      event.preventDefault()
      this.setSelectedItem({ selectedBrand: option })
      this.$emit('selectBrand')
    },
    // onClickModel(event, model) {
    //   event.preventDefault()
    //   this.$emit('selectModel', model)
    // },
    onClear(event) {
      event.preventDefault()
      this.$emit('clear')
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/mixins';

.select-menu {
  @include select-menu();
}

.sub-menu {
  .sub-item {
    padding: 0.625rem 1.125rem;
    border-top: 1px solid var(--neutral-30);
    background-color: rgba(0, 0, 0, 0.1);

    &:last-child {
      border-bottom: 1px solid var(--neutral-30);
    }

    .name {
      font-weight: 500;
    }

    .count {
      color: var(--neutral-50);
      font-size: 0.875rem;
    }
  }
}
</style>

<template>
  <div class="slider-container">
    <div ref="slider" class="slider" @scroll="onScroll">
      <slot />
    </div>
    <div class="slider-controls">
      <button v-if="scrollable" :class="{ 'slider-button': true, 'prev-button': true, flipped: textDirection === 'rtl' }" @click="movePrev" :disabled="disabledPrev">
        <ChevronLeft />
      </button>
      <button v-if="scrollable" :class="{ 'slider-button': true, 'next-button': true, flipped: textDirection === 'rtl' }" @click="moveNext" :disabled="disabledNext">
        <ChevronRight />
      </button>
    </div>
  </div>
</template>

<script>
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import ChevronRight from '@/assets/icons/chevron-right.svg'
import { debounce } from '@/helpers/debounce'
import { langMixin } from '@/lang.js'

export default {
  name: 'CarouselComponent',
  components: { ChevronLeft, ChevronRight },
  mixins: [langMixin()],
  emits: ['onChange', 'onScroll'],
  data() {
    return {
      scrollable: false,
      disabledNext: false,
      disabledPrev: false,
      resizeObserver: new ResizeObserver(this.checkScrollbars)
    }
  },
  computed: {
    isRTL() {
      return document.getElementsByTagName('html')[0]?.getAttribute('dir') === 'rtl'
    }
  },
  mounted() {
    this.resizeObserver.observe(this.$refs.slider)
    this.checkDisabled()
  },
  beforeUnmount() {
    this.resizeObserver.disconnect()
  },
  methods: {
    checkScrollbars() {
      this.scrollable = this.$el.clientWidth > 440 && this.$refs.slider.clientWidth < this.$refs.slider.scrollWidth
    },
    getStepSize() {
      return (this.$refs.slider.children?.[0]?.clientWidth ?? 0) * (this.isRTL ? -1 : 1)
    },
    movePrev() {
      const left = this.$refs.slider.scrollLeft - this.getStepSize()
      this.$refs.slider.scrollTo({ top: 0, left, behavior: 'smooth' })
      this.checkDisabled(left)
    },
    moveNext() {
      const left = this.$refs.slider.scrollLeft + this.getStepSize()
      this.$refs.slider.scrollTo({ top: 0, left, behavior: 'smooth' })
      this.checkDisabled(left)
    },
    onScroll() {
      this.$emit('onScroll')
      this.debouncedScroll()
    },
    debouncedScroll: debounce(function () {
      this.handleScroll()
    }, 200),
    handleScroll() {
      const left = this.$refs.slider.scrollLeft
      this.checkDisabled(left)
    },
    checkDisabled(left = 0) {
      const exactLeft = Math.abs(left)

      const scrollWidth = this.$refs.slider.scrollWidth
      const clientWidth = this.$refs.slider.clientWidth

      const isReachedLastElement = exactLeft + 1 >= scrollWidth - clientWidth
      const isOnFirstElement = exactLeft === 0

      if (isOnFirstElement) {
        this.disabledPrev = true
        this.disabledNext = false
      } else if (isReachedLastElement) {
        this.disabledPrev = false
        this.disabledNext = true
      } else {
        this.disabledPrev = false
        this.disabledNext = false
      }

      this.$emit('onChange', {
        disabledPrev: this.disabledPrev,
        disabledNext: this.disabledNext
      })
    }
  }
}
</script>

<style lang="scss">
.slider-container {
  position: relative;
  padding-inline-end: 0.5rem;
}

.slider {
  display: flex;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  overflow: auto;
  gap: 1rem;

  &::-webkit-scrollbar {
    display: none;
  }

  > * {
    scroll-snap-align: start;
    min-width: 20em;
  }
}

.slider-controls {
  display: flex;
  gap: 2rem;
}

.slider-button {
  background-color: none;
  border: none;
  background-color: var(--dark-background);
  width: 2.5rem;
  height: 2.5rem;
  color: var(--background);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
</style>

<template>
  <section class="testimonials vertical-padding">
    <div class="header">
      <h2 class="title">{{ t.testimonialsTitle }}</h2>
      <div class="controls">
        <button :class="{ 'slider-button': true, 'prev-button': true, flipped: textDirection === 'rtl' }" @click="movePrev" :disabled="disabledPrev">
          <ChevronLeft />
        </button>
        <button :class="{ 'slider-button': true, 'next-button': true, flipped: textDirection === 'rtl' }" @click="moveNext" :disabled="disabledNext">
          <ChevronRight />
        </button>
      </div>
    </div>
    <Carousel ref="carouselRef" class="carousel" @on-change="handleCarouselChange" @on-scroll="handleScroll">
      <TestimonialCard v-for="item in testimonials" :key="item.title" :item="item" />
    </Carousel>
    <div class="progress-bar">
      <ProgressBar :percent="percent" />
    </div>
  </section>
</template>

<script>
import Carousel from '@/components/Carousel.vue'
import TestimonialCard from '@/components/Home/TestimonialCard.vue'
import ChevronLeft from '@/assets/icons/chevron-left.svg'
import ChevronRight from '@/assets/icons/chevron-right.svg'
import { langMixin } from '@/lang.js'
import ProgressBar from '@/components/ProgressBar.vue'

export default {
  name: 'FeaturedCars',
  components: { Carousel, ChevronLeft, ChevronRight, TestimonialCard, ProgressBar },
  mixins: [langMixin('Home')],
  data() {
    return {
      disabledPrev: false,
      disabledNext: false,
      percent: 0
    }
  },
  computed: {
    testimonials() {
      return JSON.parse(this.t.testimonials || '[]')
    }
  },
  watch: {
    testimonials() {
      this.getProgressPercent()
    }
  },
  methods: {
    handleScroll() {
      this.getProgressPercent()
    },
    getProgressPercent() {
      const sliderRef = this.$refs?.carouselRef?.$refs?.slider
      this.percent = (1 / this.testimonials?.length + Math.abs(sliderRef?.scrollLeft) / sliderRef?.scrollWidth) * 100
    },
    movePrev() {
      this.$refs.carouselRef.movePrev()
    },
    moveNext() {
      this.$refs.carouselRef.moveNext()
    },
    handleCarouselChange({ disabledNext, disabledPrev }) {
      this.disabledPrev = disabledPrev
      this.disabledNext = disabledNext
    }
  }
}
</script>
<style lang="scss">
section.testimonials {
  background-color: var(--dark-background);
  color: var(--background);
  padding-inline-start: var(--content-side-padding);

  h2 {
    margin-bottom: 1rem;
    color: var(--background);
  }

  .slider {
    gap: 3.5rem;

    > * {
      min-width: calc(100% / 1.33);
    }

    @media screen and (max-width: 36rem) {
      gap: 1rem;

      > * {
        min-width: calc(100% - 1rem);
      }
    }
  }

  .slider-controls {
    display: none;
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding-inline-end: var(--content-side-padding);
    margin-bottom: 4.375rem;

    .controls {
      display: flex;
      gap: 1.5rem;

      > button {
        background-color: var(--primary);
        color: var(--dark-background);

        &:disabled {
          opacity: 0.6;
          cursor: not-allowed;
        }
      }

      @media screen and (max-width: 36rem) {
        display: none;
      }
    }
  }
}

.progress-bar {
  display: none;
  justify-content: center;
  margin-top: 2rem;

  .bar {
    background-color: var(--background);
  }

  .progress {
    background-color: var(--primary);
  }
  @media screen and (max-width: 36rem) {
    display: flex;
  }
}
</style>
